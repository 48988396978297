import { useContext, useEffect, useState, useRef, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "react-simple-snackbar";
import { useReactToPrint } from "react-to-print";
import Section from "../components/Section";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import { UserContext } from "../context/UserContext";
import { ReportContext } from "../context/ReportContext";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Row from "../components/Row";
import Column from "../components/Column";
import { format } from "date-fns";
import Pagination from "../components/Pagination";
import Loader from "../components/Loader";
import { Refresh } from "@mui/icons-material";
import ParkingReceipt from "../components/ParkingReceipt";
import Container from "../components/Container";

const Parking = () => {
	const navigate = useNavigate();
	const receiptRef = useRef();
	const scrollRef = useRef();
	const [user] = useContext(UserContext);
	const [reports, setReports] = useContext(ReportContext);
	const [transactions, fetchTransasctions] = useState([]);
	const [meta, fetchMeta] = useState([]);
	const [loading, setLoading] = useState(true);
	const [actionLoading, setActionLoading] = useState(false);
	const [refLoading, setRefLoading] = useState(false);
	const [showTransactionModal, setShowTransactionModal] = useState(false);
	const [showReportModal, setShowReportModal] = useState(false);
	const [showEndModal, setShowEndModal] = useState(false);
	const [searchParams, setSearchParams] = useSearchParams({});
	const [activeTransaction, setActiveTransaction] = useState(null);
	const [reportFrom, setReportFrom] = useState("");
	const [reportTo, setReportTo] = useState("");

	const [towerToVisit, setTowerToVisit] = useState("");
	const [unitToVisit, setUnitToVisit] = useState("");
	const [name, setName] = useState("");
	const [type, setType] = useState("Car");
	const [model, setModel] = useState("");
	const [plate, setPlate] = useState("");
	const [tower, setTower] = useState("");
	const [level, setLevel] = useState("");
	const [slot, setSlot] = useState("");
	const [ref, setRef] = useState("");
	const [ticket, setTicket] = useState("");
	const [discounted, setDiscounted] = useState(false);
	const [lostTicket, setLostTicket] = useState(false);
	const [illegalParking, setIllegalParking] = useState(false);
	const [idType, setIdType] = useState("PWD");
	const [idNo, setIdNo] = useState("");
	const [payment, setPayment] = useState("Cash");
	const [orNo, setOrNo] = useState("");

	const [searchName, setSearchName] = useState(searchParams.get("name") || "");
	const [searchSlot, setSearchSlot] = useState(searchParams.get("slot") || "");
	const [searchDate, setSearchDate] = useState(searchParams.get("date") || "");
	const [searchUnit, setSearchUnit] = useState(searchParams.get("unit") || "");
	const [searchTicket, setSearchTicket] = useState(
		searchParams.get("ticket_no") || ""
	);
	const [searchStatus, setSearchStatus] = useState(
		searchParams.get("status") || ""
	);
	const [searchPlate, setSearchPlate] = useState(
		searchParams.get("plate_no") || ""
	);
	const [searchTower, setSearchTower] = useState(
		searchParams.get("tower") || ""
	);

	const searchFields = [
		{ key: "unit", value: searchUnit },
		{ key: "name", value: searchName },
		{ key: "plate_no", value: searchPlate },
		{ key: "status", value: searchStatus },
		{ key: "date", value: searchDate },
		{ key: "ticket_no", value: searchTicket },
	];

	const [successSnackbar] = useSnackbar({
		style: {
			zIndex: 10,
			backgroundColor: "#44C4A1",
			color: "#fff",
		},
	});

	const [dangerSnackbar] = useSnackbar({
		style: {
			zIndex: 10,
			backgroundColor: "#d81e5b",
			color: "#fff",
		},
	});

	const getData = () => {
		setLoading(true);
		fetch(
			`${process.env.REACT_APP_SERVER_URI}/parking/transactions${window.location.search}`
		)
			.then((res) => res.json())
			.then((res) => {
				fetchTransasctions(res.data);
				fetchMeta(res.meta);
				setLoading(false);
			});
	};

	const handlePrint = useReactToPrint({
		content: () => receiptRef.current,
	});

	const saveTransaction = async (e) => {
		e.preventDefault();
		setActionLoading(true);
		const requestOptions = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				"X-Auth-Token": user ? user.auth_token : "",
			},
			body: JSON.stringify({
				transaction_id: activeTransaction ? activeTransaction.id : null,
				tower_to_visit: towerToVisit,
				unit_to_visit: unitToVisit,
				name: name,
				type: type,
				model: model,
				plate: plate,
				tower: tower,
				ref_no: ref,
				level: level,
				slot: slot,
				ticket_no: ticket,
				payment_mode: payment,
			}),
		};

		const response = await fetch(
			`${process.env.REACT_APP_SERVER_URI}/parking/transactions`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			dangerSnackbar("Something went wrong.");
		} else {
			handleTransactionModal(false, null);
			getData();
			successSnackbar(data.msg);
		}
		setActionLoading(false);
	};

	const endTransaction = async (e) => {
		e.preventDefault();
		setActionLoading(true);
		const requestOptions = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				"X-Auth-Token": user ? user.auth_token : "",
			},
			body: JSON.stringify({
				or_no: orNo,
				discounted: discounted,
				id_type: idType,
				id_no: idNo,
				lost_card: lostTicket,
				illegal_parking: illegalParking,
			}),
		};

		const response = await fetch(
			`${process.env.REACT_APP_SERVER_URI}/parking/transactions/${activeTransaction.id}/end`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			dangerSnackbar("Something went wrong.");
		} else {
			setActiveTransaction(data.transaction);
			getData();
			successSnackbar(data.msg);
			setShowEndModal(false);
			scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
		}
		setActionLoading(false);
	};

	const generateReport = async (e) => {
		e.preventDefault();
		setActionLoading(true);
		const requestOptions = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				"X-Auth-Token": user ? user.auth_token : "",
			},
			body: JSON.stringify({
				type: "parking",
				from: reportFrom,
				to: reportTo,
			}),
		};

		const response = await fetch(
			`${process.env.REACT_APP_SERVER_URI}/reports/generate`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			dangerSnackbar(data.msg);
		} else {
			console.log(data.report);
			setReports([...reports, data.report]);
			setShowReportModal(false);
			setReportFrom("");
			setReportTo("");
		}
		setActionLoading(false);
	};

	const getUnit = async () => {
		const requestOptions = {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				"X-Auth-Token": user ? user.auth_token : "",
			},
		};

		const response = await fetch(
			`${process.env.REACT_APP_SERVER_URI}/parking/unit/${ref}`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			dangerSnackbar(data.msg);
			setRef("");
		} else {
			setTowerToVisit(data.tower);
			setUnitToVisit(data.unit);
		}
		setRefLoading(false);
	};

	const handleTransactionModal = (state, transaction) => {
		setActiveTransaction(transaction);
		setShowTransactionModal(state);
		if (!state) {
			setTowerToVisit("");
			setUnitToVisit("");
			setName("");
			setType("Car");
			setModel("");
			setPlate("");
			setTower("");
			setLevel("");
			setSlot("");
			setRef("");
			setTicket("");
			setDiscounted(false);
			setLostTicket(false);
			setIllegalParking(false);
			setLostTicket(false);
			setIdType("PWD");
			setIdNo("");
			setPayment("Cash");
			setOrNo("");
		}
	};

	useEffect(() => {
		document.title = "Azure | Parking";
		getData();
	}, []);

	useEffect(() => {
		const timer = setTimeout(() => {
			var newSearch = { ...searchParams };

			searchFields.map((field) => {
				if (field.value !== "") {
					newSearch[field.key] = field.value;
				} else {
					delete newSearch[field.key];
				}
			});
			setSearchParams(newSearch);
		}, 500);
		return () => clearTimeout(timer);
	}, [
		searchUnit,
		searchName,
		searchPlate,
		searchStatus,
		searchDate,
		searchTicket,
	]);

	useEffect(() => {
		getData();
	}, [searchParams]);

	useMemo(() => {
		if (ref.length === 12) {
			setRefLoading(true);
			getUnit();
		}
	}, [ref]);

	return (
		<>
			<div className="page-header flex-row-left">
				<span className="back-label default-text">Pay Parking</span>
			</div>
			<Section className="content">
				<div className="content-header mb-2 flex-row-right">
					<Pagination
						meta={meta}
						searchParams={searchParams}
						setSearchParams={setSearchParams}
						searchFields={searchFields}
						className="pull-right flex-row-left"
					/>
					<Refresh
						className="refresh-btn blue-text ms-2 me-1 pointer"
						sx={{ fontSize: 28 }}
						onClick={getData}
					/>
					<Button
						variant="primary"
						className="booking-action-btn"
						onClick={() => setShowTransactionModal(true)}
					>
						Add
					</Button>
					<Button
						variant="primary"
						className="booking-action-btn"
						onClick={() => setShowReportModal(true)}
					>
						Export
					</Button>
					<a
						href="https://www.mycondopal.com/azure-perimeter-parking"
						target="_blank"
					>
						<Button variant="primary" className="booking-action-btn">
							Available Slots
						</Button>
					</a>
				</div>
				<Table className="content-table white-bg">
					<thead className="blue-text">
						<tr>
							<th>Date</th>
							<th>Unit</th>
							<th>Ticket No.</th>
							<th>Name</th>
							<th>Plate No.</th>
							<th>Status</th>
						</tr>
						<tr className="search-tr">
							<th>
								<Form.Control
									type="date"
									value={searchDate}
									onChange={(e) => setSearchDate(e.target.value)}
								/>
							</th>
							<th>
								<Form.Control
									type="text"
									value={searchUnit}
									onChange={(e) => setSearchUnit(e.target.value)}
								/>
							</th>
							<th>
								<Form.Control
									type="text"
									value={searchTicket}
									onChange={(e) => setSearchTicket(e.target.value)}
								/>
							</th>
							<th>
								<Form.Control
									type="text"
									value={searchName}
									onChange={(e) => setSearchName(e.target.value)}
								/>
							</th>
							<th>
								<Form.Control
									type="text"
									value={searchPlate}
									onChange={(e) => setSearchPlate(e.target.value)}
								/>
							</th>
							<th>
								<Form.Control
									type="text"
									value={searchStatus}
									onChange={(e) => setSearchStatus(e.target.value)}
								/>
							</th>
						</tr>
					</thead>
					{loading ? (
						<Loader className="content-loader white-bg" />
					) : transactions && transactions.length != 0 ? (
						<tbody>
							{transactions.map((transaction) => (
								<tr
									key={transaction.id}
									onClick={() => handleTransactionModal(true, transaction)}
								>
									<td>
										{format(new Date(transaction.created_at), "MMM dd, yyyy")}
									</td>
									<td>
										{transaction.tower_to_visit} - {transaction.unit_to_visit}
									</td>
									<td>{transaction.ticket_no || "N/A"}</td>
									<td>{transaction.guest_name}</td>
									<td>{transaction.vehicle_plate_no}</td>
									<td>{transaction.status}</td>
								</tr>
							))}
						</tbody>
					) : (
						<div className="empty-content white-bg flex-column">
							<span>No records found.</span>
						</div>
					)}
				</Table>
			</Section>
			<Modal
				size="lg"
				show={showTransactionModal}
				onHide={() => handleTransactionModal(false, null)}
			>
				<div
					className={`parking-overlay flex-column ${refLoading && "active"}`}
				>
					<span className="loader-wheel" />
				</div>
				<Modal.Header>
					<Modal.Title className="popup-modal-header medium-text">
						{activeTransaction ? "Transaction Info" : "New Transaction"}
					</Modal.Title>
				</Modal.Header>
				<Form onSubmit={saveTransaction}>
					<Modal.Body ref={scrollRef} className="pt-4 pb-4">
						{/* {activeTransaction && activeTransaction.booking_ref && (
							<Row className="mb-4">
								<Column>
									<Form.Label>Ref No.</Form.Label>
									<Form.Control
										required
										readOnly
										type="text"
										value={activeTransaction.booking_ref}
									/>
								</Column>
								<Column />
							</Row>
						)} */}
						<Row className="mb-4">
							<Column>
								<Form.Label>Reference No. (Optional)</Form.Label>
								<Form.Control
									readOnly={activeTransaction && activeTransaction.ref_no}
									type="text"
									value={
										activeTransaction && activeTransaction.ref_no
											? activeTransaction.ref_no
											: ref
									}
									onChange={(e) => setRef(e.target.value)}
								/>
							</Column>
						</Row>
						<Row className="mb-4">
							<Column>
								<Form.Label>Tower to Visit</Form.Label>
								<Form.Select
									required
									readOnly={
										activeTransaction && activeTransaction.tower_to_visit
									}
									disabled={
										activeTransaction && activeTransaction.tower_to_visit
									}
									className="text-sm m-0 form-control"
									size="lg"
									value={
										activeTransaction && activeTransaction.tower_to_visit
											? activeTransaction.tower_to_visit
											: towerToVisit
									}
									onChange={(e) => setTowerToVisit(e.target.value)}
								>
									<option value=""></option>
									<option value="Bahamas">Bahamas</option>
									<option value="Boracay">Boracay</option>
									<option value="Miami">Miami</option>
									<option value="Maldives">Maldives</option>
									<option value="Maui">Maui</option>
									<option value="Positano">Positano</option>
									<option value="Rio East">Rio East</option>
									<option value="Rio West">Rio West</option>
									<option value="Santorini">Santorini</option>
									<option value="St. Tropez">St. Tropez</option>
								</Form.Select>
							</Column>
							<Column>
								<Form.Label>Unit to Visit</Form.Label>
								<Form.Control
									required
									readOnly={
										activeTransaction && activeTransaction.unit_to_visit
									}
									type="text"
									value={
										activeTransaction && activeTransaction.unit_to_visit
											? activeTransaction.unit_to_visit
											: unitToVisit
									}
									onChange={(e) => setUnitToVisit(e.target.value)}
								/>
							</Column>
						</Row>
						<Row className="mb-4">
							<Column>
								<Form.Label>Name</Form.Label>
								<Form.Control
									className="capitalize"
									required
									readOnly={activeTransaction}
									type="text"
									value={
										activeTransaction ? activeTransaction.guest_name : name
									}
									onChange={(e) => setName(e.target.value)}
								/>
							</Column>
						</Row>
						<Row className="mb-4">
							<Column>
								<Form.Label>Vehicle Type</Form.Label>
								<Form.Select
									required
									readOnly={activeTransaction}
									disabled={activeTransaction}
									className="text-sm m-0 form-control"
									size="lg"
									value={
										activeTransaction ? activeTransaction.vehicle_type : type
									}
									onChange={(e) => setType(e.target.value)}
								>
									<option value="Car">Car</option>
									<option value="Motorcycle">Motorcycle</option>
								</Form.Select>
							</Column>
						</Row>
						<Row className="mb-4">
							<Column>
								<Form.Label>Make & Model</Form.Label>
								<Form.Control
									className="capitalize"
									required
									readOnly={activeTransaction}
									type="text"
									value={
										activeTransaction ? activeTransaction.vehicle_model : model
									}
									onChange={(e) => setModel(e.target.value)}
								/>
							</Column>
							<Column>
								<Form.Label>Plate No.</Form.Label>
								<Form.Control
									required
									className="uppercase"
									readOnly={activeTransaction}
									type="text"
									value={
										activeTransaction
											? activeTransaction.vehicle_plate_no
											: plate
									}
									onChange={(e) => setPlate(e.target.value)}
								/>
							</Column>
						</Row>
						<Row className="mb-4">
							<Column>
								<Form.Label>Mode of Payment</Form.Label>
								<Form.Select
									required
									readOnly={activeTransaction && activeTransaction.payment_mode}
									disabled={activeTransaction && activeTransaction.payment_mode}
									className="text-sm m-0 form-control"
									size="lg"
									value={
										activeTransaction && activeTransaction.payment_mode
											? activeTransaction.payment_mode
											: payment
									}
									onChange={(e) => setPayment(e.target.value)}
								>
									<option value="Cash">Cash</option>
									<option value="Charge">Charge to Unit</option>
								</Form.Select>
							</Column>
						</Row>
						<Row className="mb-4">
							<Column>
								<Form.Label>Parking Tower</Form.Label>
								<Form.Select
									required
									readOnly={
										activeTransaction && activeTransaction.parking_tower
									}
									disabled={
										activeTransaction && activeTransaction.parking_tower
									}
									className="text-sm m-0 form-control"
									size="lg"
									value={
										activeTransaction && activeTransaction.parking_tower
											? activeTransaction.parking_tower
											: tower
									}
									onChange={(e) => setTower(e.target.value)}
								>
									<option value=""></option>
									<option value="Perimeter Parking">Perimeter Parking</option>
									<option value="Bahamas">Bahamas</option>
									<option value="Boracay">Boracay</option>
									<option value="Miami">Miami</option>
									<option value="Maldives">Maldives</option>
									<option value="Maui">Maui</option>
									<option value="Positano">Positano</option>
									<option value="Rio East">Rio East</option>
									<option value="Rio West">Rio West</option>
									<option value="Santorini">Santorini</option>
									<option value="St. Tropez">St. Tropez</option>
								</Form.Select>
							</Column>
							{activeTransaction &&
							activeTransaction.parking_tower &&
							activeTransaction.parking_tower !== "Perimeter Parking" ? (
								<Column>
									<Form.Label>Parking Level</Form.Label>
									<Form.Select
										required
										readOnly={
											activeTransaction && activeTransaction.parking_level
										}
										disabled={
											activeTransaction && activeTransaction.parking_level
										}
										className="text-sm m-0 form-control"
										size="lg"
										value={
											activeTransaction && activeTransaction.parking_level
												? activeTransaction.parking_level
												: level
										}
										onChange={(e) => setLevel(e.target.value)}
									>
										<option value=""></option>
										<option value="Basement 1">Basement 1</option>
										<option value="Basement 2">Basement 2</option>
										<option value="Basement 3">Basement 3</option>
										<option value="Basement 4">Basement 4</option>
									</Form.Select>
								</Column>
							) : tower !== "Perimeter Parking" && tower !== "" ? (
								<Column>
									<Form.Label>Parking Level</Form.Label>
									<Form.Select
										required
										readOnly={
											activeTransaction && activeTransaction.parking_level
										}
										disabled={
											activeTransaction && activeTransaction.parking_level
										}
										className="text-sm m-0 form-control"
										size="lg"
										value={
											activeTransaction && activeTransaction.parking_level
												? activeTransaction.parking_level
												: level
										}
										onChange={(e) => setLevel(e.target.value)}
									>
										<option value=""></option>
										<option value="Basement 1">Basement 1</option>
										<option value="Basement 2">Basement 2</option>
										<option value="Basement 3">Basement 3</option>
										<option value="Basement 4">Basement 4</option>
									</Form.Select>
								</Column>
							) : (
								""
							)}
							<Column>
								<Form.Label>Parking Slot</Form.Label>
								<Form.Control
									required
									readOnly={activeTransaction && activeTransaction.parking_slot}
									type="text"
									value={
										activeTransaction && activeTransaction.parking_slot
											? activeTransaction.parking_slot
											: slot
									}
									onChange={(e) => setSlot(e.target.value)}
								/>
							</Column>
						</Row>
						<Row className="mb-4">
							<Column>
								<Form.Label>Ticket No.</Form.Label>
								<Form.Control
									required
									readOnly={activeTransaction && activeTransaction.ticket_no}
									type="text"
									value={
										activeTransaction && activeTransaction.ticket_no
											? activeTransaction.ticket_no
											: ticket
									}
									onChange={(e) => setTicket(e.target.value)}
								/>
							</Column>
						</Row>
						{activeTransaction &&
							activeTransaction.status !== "Pending" &&
							activeTransaction.status !== "Expired" && (
								<>
									<ParkingReceipt
										ref={receiptRef}
										transaction={activeTransaction}
									/>
									<Table>
										<tbody>
											<tr>
												<td className="text-sm">OR Number:</td>
												<td className="align-right text-sm">
													{activeTransaction.or_no}
												</td>
											</tr>
											<tr>
												<td className="text-sm">Start:</td>
												<td className="align-right text-sm">
													{format(
														new Date(activeTransaction.start),
														"MMM dd, yyyy (hh:mm a)"
													)}
												</td>
											</tr>
											<tr>
												<td className="text-sm">End:</td>
												<td className="align-right text-sm">
													{activeTransaction.status === "Finished"
														? format(
																new Date(activeTransaction.end),
																"MMM dd, yyyy (hh:mm a)"
														  )
														: "--"}
												</td>
											</tr>
											<tr>
												<td className="text-sm">Hours:</td>
												<td className="align-right text-sm">
													{activeTransaction.status === "Finished"
														? activeTransaction.hours
														: "--"}
												</td>
											</tr>
											<tr>
												<td className="text-sm">Discount Type:</td>
												<td className="align-right text-sm">
													{activeTransaction.status === "Finished" &&
													activeTransaction.discounted
														? activeTransaction.id_type
														: "--"}
												</td>
											</tr>
											<tr>
												<td className="text-sm">Discount ID No:</td>
												<td className="align-right text-sm">
													{activeTransaction.status === "Finished" &&
													activeTransaction.discounted
														? activeTransaction.id_no
														: "--"}
												</td>
											</tr>
											<tr>
												<td className="text-sm">Vatable Sales:</td>
												<td className="align-right text-sm">
													{activeTransaction.status === "Finished"
														? `₱${activeTransaction.vatable_sales}`
														: "--"}
												</td>
											</tr>
											<tr>
												<td className="text-sm">VAT Exempt Sales:</td>
												<td className="align-right text-sm">
													{activeTransaction.status === "Finished"
														? `₱${activeTransaction.vat_exempt_sales}`
														: "--"}
												</td>
											</tr>
											<tr>
												<td className="text-sm">VAT:</td>
												<td className="align-right text-sm">
													{activeTransaction.status === "Finished"
														? `₱${activeTransaction.vat}`
														: "--"}
												</td>
											</tr>
											<tr>
												<td className="text-sm">Subtotal:</td>
												<td className="align-right text-sm">
													{activeTransaction.status === "Finished"
														? `₱${activeTransaction.total}`
														: "--"}
												</td>
											</tr>
											<tr>
												<td className="text-sm">Discount:</td>
												<td className="align-right text-sm">
													{activeTransaction.status === "Finished"
														? `₱${activeTransaction.discount}`
														: "--"}
												</td>
											</tr>
											{/* {activeTransaction.status === "Finished" &&
												activeTransaction.lost_card && (
													<tr>
														<td className="text-sm">Lost Ticket:</td>
														<td className="align-right text-sm">
															₱{activeTransaction.lost_card_fee}
														</td>
													</tr>
												)}
											{activeTransaction.status === "Finished" &&
												activeTransaction.illegal_parking && (
													<tr>
														<td className="text-sm">Illegal Parking:</td>
														<td className="align-right text-sm">
															₱{activeTransaction.illegal_parking_fee}
														</td>
													</tr>
												)} */}
											<tr>
												<td className="bold-text">GRAND TOTAL:</td>
												<td className="align-right bold-text">
													{activeTransaction.status === "Finished"
														? `₱${activeTransaction.grand_total}`
														: "--"}
												</td>
											</tr>
										</tbody>
									</Table>
								</>
							)}
					</Modal.Body>
					<Modal.Footer>
						<Button
							variant="outline-dark"
							className="modal-btn bold-text text-sm me-2 dismiss-btn"
							onClick={() => handleTransactionModal(false, null)}
						>
							{activeTransaction ? "Close" : "Cancel"}
						</Button>
						{activeTransaction && activeTransaction.status === "Active" ? (
							<Button
								type="button"
								variant="primary"
								className="modal-btn bold-text text-sm"
								onClick={() => setShowEndModal(true)}
							>
								End
							</Button>
						) : activeTransaction && activeTransaction.status === "Finished" ? (
							<Button
								variant="outline-dark"
								className="modal-btn bold-text text-sm me-2 dismiss-btn"
								onClick={handlePrint}
							>
								Print
							</Button>
						) : (
							<Button
								type="submit"
								variant="primary"
								className="modal-btn bold-text text-sm"
								disabled={actionLoading}
							>
								{actionLoading ? "Loading" : "Start"}
							</Button>
						)}
					</Modal.Footer>
				</Form>
			</Modal>
			<Modal show={showEndModal} onHide={() => setShowEndModal(false)} centered>
				<Modal.Header>
					<Modal.Title className="popup-modal-header medium-text">
						End Transaction
					</Modal.Title>
				</Modal.Header>
				<Form onSubmit={endTransaction}>
					<Modal.Body className="pt-4 pb-4">
						<Row className="flex-row-left mb-4">
							{/* <Column>
								<span className="blue-text form-label inline">OR No.</span>
							</Column> */}
							<Column>
								<Form.Label>OR Number</Form.Label>
								<Form.Control
									autoFocus
									type="text"
									value={orNo}
									onChange={(e) => setOrNo(e.target.value)}
									required
								/>
							</Column>
						</Row>
						<Row className="flex-row-left mb-4">
							<Column>
								<span className="blue-text form-label inline">
									PWD/Senior Discount
								</span>
							</Column>
							<Column className="align-right">
								<input
									type="checkbox"
									className="cbx hidden"
									checked={discounted}
									id="discounted"
									onChange={() => setDiscounted(!discounted)}
								/>
								<label htmlFor="discounted" className="lbl"></label>
							</Column>
						</Row>
						{discounted && (
							<Row className="mb-4">
								<Column>
									<Form.Label>ID Type</Form.Label>
									<Form.Select
										className="form-control text-sm"
										value={idType}
										onChange={(e) => setIdType(e.target.value)}
									>
										<option value="PWD">PWD</option>
										<option value="Senior">Senior</option>
									</Form.Select>
								</Column>
								<Column>
									<Form.Label>ID No.</Form.Label>
									<Form.Control
										required
										type="text"
										className="form-control text-sm"
										value={idNo}
										onChange={(e) => setIdNo(e.target.value)}
									/>
								</Column>
							</Row>
						)}
						<Row className="flex-row-left mb-4">
							<Column>
								<span className="blue-text form-label inline">Lost Ticket</span>
							</Column>
							<Column className="align-right">
								<input
									type="checkbox"
									className="cbx hidden"
									checked={lostTicket}
									id="lostTicket"
									onChange={() => setLostTicket(!lostTicket)}
								/>
								<label htmlFor="lostTicket" className="lbl"></label>
							</Column>
						</Row>
						<Row className="flex-row-left mb-4">
							<Column>
								<span className="blue-text form-label inline">
									Illegal Parking
								</span>
							</Column>
							<Column className="align-right">
								<input
									type="checkbox"
									className="cbx hidden"
									checked={illegalParking}
									id="illegalParking"
									onChange={() => setIllegalParking(!illegalParking)}
								/>
								<label htmlFor="illegalParking" className="lbl"></label>
							</Column>
						</Row>
					</Modal.Body>
					<Modal.Footer>
						<Button
							variant="outline-dark"
							className="modal-btn bold-text text-sm me-2 dismiss-btn"
							onClick={() => setShowEndModal(false)}
						>
							Cancel
						</Button>
						<Button
							type="submit"
							variant="primary"
							className="modal-btn bold-text text-sm"
							disabled={actionLoading}
						>
							{actionLoading ? "Loading" : "End"}
						</Button>
					</Modal.Footer>
				</Form>
			</Modal>
			<Modal show={showReportModal} onHide={() => setShowReportModal(false)}>
				<Modal.Header>
					<Modal.Title className="popup-modal-header medium-text">
						Generate Report
					</Modal.Title>
				</Modal.Header>
				<Form onSubmit={generateReport}>
					<Modal.Body>
						<Container>
							<Row className="mb-5">
								<Column>
									<Form.Label>From</Form.Label>
									<Form.Control
										required
										type="date"
										value={reportFrom}
										onChange={(e) => setReportFrom(e.target.value)}
									/>
								</Column>
								<Column>
									<Form.Label>To</Form.Label>
									<Form.Control
										required
										type="date"
										value={reportTo}
										onChange={(e) => setReportTo(e.target.value)}
									/>
								</Column>
							</Row>
						</Container>
					</Modal.Body>
					<Modal.Footer>
						<Button
							variant="outline-dark"
							className="modal-btn bold-text text-sm me-2 dismiss-btn"
							onClick={() => setShowReportModal(false)}
						>
							Cancel
						</Button>
						<Button
							type="submit"
							variant="primary"
							className="modal-btn bold-text text-sm"
							disabled={actionLoading}
						>
							{actionLoading ? "Loading" : "Generate"}
						</Button>
					</Modal.Footer>
				</Form>
			</Modal>
		</>
	);
};

export default Parking;
